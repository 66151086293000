// console.log(process.env.BASE_URL,"baseurl")
let url = process.env.REACT_APP_API_PATH;
console.log(url,"baseurl")

export const configuration={
    details_before_checkout:url+"/detailsBeforeCheckOut",
    details_after_payment:url+"/detailsAfterPayment",
    get_all_plans:url+"/getAllPlans",
    subscribe_to_plan:url+"/subscribeToPlan",
    updateFinal:url+"/updatePlan",
    get_details_of_current_subscription:url+"/getDetailsOfCurrentSubscription",
}

// https://unified-api.dev.tourwizonline.com/zoho/getAllPlans?currency_code=INR
// let url = "https://unified-api.dev.tourwizonline.com/zoho";

// export const configuration={
//     details_before_checkout:url+"/details_before_checkout",
//     details_after_payment:url+"/details_after_payment",
//     get_all_plans:url+"/get_all_plans",
//     subscribe_to_plan:url+"/subscribe_to_plan",
//     updateFinal:url+"/updateFinal",
//     get_details_of_current_subscription:url+"/get_details_of_current_subscription",
// }